

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input {
  background-color: rgba(0,0,0,0);
  color: #fff;
  text-align: center;
  padding: 7px;
  margin: 7px;
  border-radius: 7px;
  width: 100%;
}

textarea {
  background-color: rgba(0,0,0,0);
  color: #fff;
  border-radius: 7px;
  width: 100%;
  padding: 7px;
  margin: 7px;
  height: 50px;
}

label {
  padding: 7px;
  margin: 7px;
}

form {
  text-align: left;
  width: 80%;
  margin: auto;
}

.main {
  margin: 24px;
}



.userName {
  font-size: 1.5em;
  font-family: monospace;
  position: absolute;
  top: 24px;
  right: 24px;
}

.header {
  text-align: center;
}

.register {
  border: 1px solid #fff;
  width: 50%;
  margin: auto;
  padding: 24px;
  border-radius: 7px;
  text-align: center;
}

.create {
  border: 1px solid #fff;
  width: 50%;
  margin: auto;
  margin-top: 24px;
  padding: 24px;
  border-radius: 7px;
  text-align: center;
}

.complete {
  border: 1px solid #fff;
  width: 50%;
  margin: auto;
  margin-top: 24px;
  padding: 24px;
  border-radius: 7px;
  text-align: center;
}

.rate {
  border: 1px solid #fff;
  width: 50%;
  margin: auto;
  margin-top: 24px;
  padding: 24px;
  border-radius: 7px;
  text-align: center;
}

.info {
  border: 1px solid #fff;
  width: 50%;
  margin: auto;
  margin-top: 24px;
  padding: 24px;
  border-radius: 7px;
  text-align: center;
}

.bounty {
  border: 1px solid #fff;
  width: 50%;
  margin: auto;
  margin-top: 24px;
  padding: 24px;
  border-radius: 7px;
  text-align: center;
}

.nav {
  overflow: hidden;
  text-align: center;
  font-size: 10px;
  display: flex;
  width: 100%;
  margin-bottom: 24px;
}

.nav div {
  padding: 24px;
  margin: auto;
  justify-content: space-between;
  max-width: 150px;
  text-align: center;
  padding-bottom: 0px;
  border-bottom: 1px solid rgba(0,0,0,0);
  cursor: pointer;
}

.nav div:hover {
  border-bottom: 1px solid #fff;
}

.signBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign {
  font-size: 1.2em;
  font-family: monospace;
  padding: 7px 14px;
  margin-top: 20vh;
  font-size: calc(10px + 2vmin);
}

.bountyHunter {
  background-color: #282c34;
  color: #fff;
  font-size: calc(10px + 2vmin);
  width: 50%;
  margin: auto;
  margin-top: 150px;
  border: 1px solid #fff;
  border-radius: 7px;
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  padding: 24px;
  box-shadow: 5px 5px 10px #161515;
}

.bounties {
  margin: 24px;
  border: 1px solid #fff;
}

.claim {
  margin: 14px;
  font-family: monospace;
  font-size: 2vw;
}

.claim-green {
  background-color: rgb(201, 35, 35);
  color: white;
}

.bountyHunters {
  margin: 24px;
  border: 1px solid #fff;
}